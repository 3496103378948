import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import HeaderModules from 'utils/Data/HeaderModules';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import axios from 'axios';
import { BASEURL, headerColorCode } from 'utils/Constants';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    Grid,
    useScrollTrigger
} from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import useAuth from 'hooks/useAuth';

// assets
import MenuIcon from '@mui/icons-material/Menu';

// elevation scroll
export interface ElevationScrollProps {
    children: ReactElement;
    window?: Window | Node;
}
function ElevationScroll(props: ElevationScrollProps) {
    const { children, window } = props;
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window!
    });
    const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
        style: {
            // backgroundColor: '#2e3e80',
            backgroundColor: headerColorCode,
            borderBottom: trigger ? 'none' : '1px solid',
            borderColor: trigger ? '' : darkBorder,
            color: 'white'
        }
    });
}

// ==============================|| MINIMAL LAYOUT APP BAR ||============================== //

const CustomHeader = ({ ...others }) => {
    const [drawerToggle, setDrawerToggle] = React.useState<boolean>(false);
    const { logout } = useAuth();
    /** Method called on multiple components with different event types */
    const drawerToggler = (open: boolean) => (event: any) => {
        if (event.type! === 'keydown' && (event.key! === 'Tab' || event.key! === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };
    const handleLogout = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            await axios
                .get(`${BASEURL}/user/data_service.php?service=logout`, {
                    headers: {
                        _token: serviceToken
                    }
                })
                .then((response) => {})
                .catch((error) => {});
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <ElevationScroll {...others}>
            <MuiAppBar sx={{ justifyContent: 'center', maxHeight: '75px' }}>
                <Container>
                    <Toolbar>
                        <Grid container justifyContent="left">
                            <Grid item md={6} lg={3} xs={12}>
                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                    <Button component={RouterLink} to="/home">
                                        <Logo />
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="right">
                            <Stack direction="row" sx={{ display: { xs: 'none', sm: 'none', md: 'block', xl: 'block' } }} spacing={2}>
                                {HeaderModules.map((e, index) => (
                                    <Button color="inherit" component={RouterLink} to={e.link} key={index}>
                                        {e.name}
                                    </Button>
                                ))}
                                <Button color="success">
                                    <ProfileSection />
                                </Button>
                            </Stack>
                            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', xl: 'none' } }}>
                                <IconButton color="inherit" onClick={drawerToggler(true)} size="large">
                                    <MenuIcon />
                                </IconButton>
                                <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                                    <Box
                                        sx={{
                                            width: 'auto'
                                        }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            {HeaderModules.map((e, index) => (
                                                <Link style={{ textDecoration: 'none' }} component={RouterLink} to={e.link} key={index}>
                                                    <ListItemButton component="a">
                                                        <ListItemText primary={e.name} />
                                                    </ListItemButton>
                                                </Link>
                                            ))}
                                        </List>
                                        <List>
                                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to="/profile">
                                                <ListItemButton component="a">
                                                    <ListItemText primary="Profile" />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }} onClick={handleLogout}>
                                                <ListItemButton component="a">
                                                    <ListItemText primary="Logout" />
                                                </ListItemButton>
                                            </Link>
                                        </List>
                                    </Box>
                                </Drawer>
                            </Box>
                        </Grid>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default CustomHeader;
