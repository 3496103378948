export const SERVICE_NAME = 'data_service.php?service';
export const MOODLE_SERVICE = 'moodle_mobile_app';
export const BASEURL = process.env.REACT_APP_SERVICE_URL;
export const BACKEND_URL = 'https://admin-cnhaelearning.mpower-social.com';
export const captChaSecretKey = '6LdGhfImAAAAAL9Y0TT_o_DF6dAbWdloJfFj8E8p';
export const captChaSiteKey = '6Ld1nfImAAAAAHvepnv4J62rg1rAvedSyJaA5Fea';

export const localApiBaseUrl = 'http://localhost/shn-web/local/shn_api';
export const devApiBaseUrl = 'https://admin-cnhaelearning.mpower-social.com/local/shn_api/';
export const qaApiBaseUrl = 'https://shn-elearning.qa.mpower-social.com/local/shn_api/';
export const chorchaApiBaseUrl = 'https://admin.chorcha.shnnetwork.org//local/shn_api/';
export const headerColorCode = '#00B0F0';
// export const IMAGEPATH = process.env.IMAGE_BASE_URL;
export type PostDataType = {
    data: {
        type: string;
        attributes: any;
    };
};
