// material-ui
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
// import AppBar from 'ui-component/extended/AppBar';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => (
    <>
        <Grid
            marginLeft={{ xs: '50px', md: '13%', lg: '13%', xl: '20%' }}
            marginRight={{ xs: '50px', md: '13%', lg: '13%', xl: '20%' }}
            marginBottom={{ xs: 2, md: 10, lg: 10 }}
        >
            <Outlet />
        </Grid>
    </>
);

export default MainLayout;
