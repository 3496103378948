import { lazy } from 'react';

// project imports
// import MinimalLayout from 'layout/MinimalLayout';
import MobileLayout from 'layout/MobileLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const CourseContent = Loadable(lazy(() => import('views/pages/CourseContent/MobileContent')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MobileLayout />,
    children: [
        {
            path: '/mobile-content/:id/:token',
            element: <CourseContent />
        }
    ]
};

export default MainRoutes;
