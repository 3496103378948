import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
// import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, USER_UPDATE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
// import axios from 'utils/axios';
import axios from 'axios';
import axiosServices from 'utils/axiosServices';
import { initialLoginContextProps } from 'types';
import { JWTContextType } from 'types/auth';
import { BASEURL, MOODLE_SERVICE, SERVICE_NAME } from 'utils/Constants';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const chance = new Chance();

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// const verifyToken: (st: string) => boolean = (serviceToken) => {
//     if (!serviceToken) {
//         return false;
//     }
//     const decoded: KeyedObject = jwtDecode(serviceToken);
//     /**
//      * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//      */
//     return decoded.exp > Date.now() / 1000;
// };

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = cookies.get('serviceToken');
                if (serviceToken) {
                    setSession(serviceToken);
                    await axios
                        .get(`${BASEURL}/user/data_service.php?service=get_user_profile`, {
                            headers: {
                                Accept: '*/*',
                                Authorization: serviceToken
                            }
                        })
                        .then((response) => {
                            if (response.data.success === true) {
                                dispatch({
                                    type: LOGIN,
                                    payload: {
                                        isLoggedIn: true,
                                        user: {
                                            id: response.data.response.userid,
                                            username: response.data.response.username,
                                            firstname: response.data.response.firstname,
                                            lastname: response.data.response.lastname,
                                            age: response.data.response.age,
                                            gender: response.data.response.gender,
                                            email: response.data.response.email,
                                            location: response.data.response.address,
                                            phone: response.data.response.phone,
                                            isFromShn: response.data.response.isFromShn,
                                            birth: response.data.response.birth,
                                            email2: response.data.response.email2,
                                            department: response.data.response.department,
                                            education: response.data.response.education,
                                            designation: response.data.response.designation,
                                            organization: response.data.response.organization,
                                            employeeid: response.data.response.employeeid,
                                            phclocation: response.data.response.phclocation,
                                            profile_pic: response.data.response.profile_pic
                                        }
                                    }
                                });
                            } else {
                                dispatch({
                                    type: LOGOUT
                                });
                            }
                        })
                        .catch((error) => {
                            dispatch({
                                type: LOGOUT
                            });
                        });
                    // dispatch({
                    //     type: LOGIN,
                    //     payload: {
                    //         isLoggedIn: true,
                    //         user: {
                    //             name: 'toothless'
                    //         }
                    //     }
                    // });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                    localStorage.removeItem('serviceToken');
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
        // eslint-disable-next-line
    }, [cookies.get('serviceToken')]);

    // eslint-disable-next-line consistent-return
    const login = async (email: string, password: string) => {
        const url = `${BASEURL}/user/${SERVICE_NAME}=login`;
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);
        formData.append('service', MOODLE_SERVICE);
        const response = await axiosServices.post(url, formData);
        if (response.data.success === true) {
            const { token } = response.data.response;
            const checked = localStorage.getItem('Remember_me') === 'true';
            if (checked) {
                cookies.set('serviceToken', token, { path: '/', maxAge: 60 * 60 * 24 * 30 });
            } else {
                cookies.set('serviceToken', token, { path: '/', maxAge: 60 * 60 * 24 * 7 });
            }

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        id: response.data.response.userid,
                        username: response.data.response.username,
                        firstname: response.data.response.firstname,
                        lastname: response.data.response.lastname,
                        gender: response.data.response.gender,
                        email: response.data.response.email,
                        phone: response.data.response.phone,
                        isFromShn: response.data.response.isFromShn,
                        birth: response.data.response.birth,
                        email2: response.data.response.email2,
                        department: response.data.response.department,
                        designation: response.data.response.designation,
                        organization: response.data.response.organization,
                        employeeid: response.data.response.employeeid,
                        phclocation: response.data.response.phclocation,
                        profile_pic: response.data.response.profile_pic
                    }
                }
            });
            navigate('/home', { replace: true });
        } else {
            dispatch({ type: LOGOUT });
        }
        return response.data;
    };

    const register = async (email: string, password: string, firstName: string, lastName: string) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        cookies.remove('serviceToken');
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (username: string) => {
        const formData = new FormData();
        formData.append('cred', username);
        const url = `${BASEURL}/user/${SERVICE_NAME}=reset_password`;
        const response = await axiosServices.post(url, formData);
        window.localStorage.setItem('reset_token', response.data.response.token);
        window.localStorage.setItem('username', response.data.response.username);
        return response.data;
    };

    const verifyOtp = async (username: string, otp: number | string) => {
        console.log(username + otp);
    };

    const updateProfile = (data: any) => {
        console.log(data);
        dispatch({
            type: USER_UPDATE,
            payload: {
                isLoggedIn: true,
                user: {
                    id: data.userid,
                    username: data.username,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    age: data.age,
                    gender: data.gender,
                    email: data.email,
                    phone: data.phone,
                    profile_pic: data.profile_pic,
                    birth: data.birth,
                    education: data.education,
                    email2: data.email2,
                    designation: data.designation,
                    department: data.department,
                    organization: data.organization,
                    employeeid: data.employeeid,
                    phclocation: data.phclocation
                }
            }
        });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, verifyOtp }}>
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
