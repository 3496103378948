// material-ui
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Grid>
            <Outlet />
        </Grid>
    </>
);

export default MinimalLayout;
