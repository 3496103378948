// import { CourseType } from 'types/course';
// import { COURSE_REOMVE, COURSE_SELECT } from './actions';

import { SET_COURSE_DETAILS, SET_ONGOING_COURSES, SET_ENROLLED_COURSES, COURSE_SELECT, COURSE_REOMVE } from './actions';

// export interface CourseReducerActionProps {
//     type: string;
//     course: CourseType | null;
// }

const initialState: any = {
    details: null,
    ongoingCourses: null,
    enrolledCourses: null
};

const courseReducer = (state = initialState, action: any) => {
    const course = action.course || null;
    switch (action.type) {
        case SET_COURSE_DETAILS:
            return {
                ...state,
                details: {
                    id: action.courseDetails?.info.id,
                    categoryName: action.courseDetails?.info.category_name,
                    courseCompletionPercentage: action.courseDetails?.info.courseCompletionPercentage,
                    courseName: action.courseDetails?.info.course_name,
                    coursePic: action.courseDetails?.info.coursepic,
                    description: action.courseDetails?.info.description,
                    isEnrolled: action.courseDetails?.info.isEnrolled,
                    teacherName: action.courseDetails?.info.teacher_name,
                    sectionList: action.courseDetails?.sectionList,
                    forum: action.courseDetails?.forum,
                    startDate: action.courseDetails?.info.startdate,
                    endDate: action.courseDetails?.info.enddate,
                    deadline: action.courseDetails?.info.deadline,
                    oneTimeFee: action.courseDetails?.info.onetimefee,
                    yearlyFee: action.courseDetails?.info.yearlyfee,
                    sixMonthlyFee: action.courseDetails?.info.sixmonthlyfee,
                    threeMonthlyFee: action.courseDetails?.info.threemonthlyfee,
                    weeklyFee: action.courseDetails?.info.weeklyfee,
                    discount: action.courseDetails?.info.discount,
                    subscription: action.courseDetails?.subscription
                }
            };
        case SET_ONGOING_COURSES:
            return {
                ...state,
                ongoingCourses: action.ongoingCourses
            };
        case SET_ENROLLED_COURSES:
            return {
                ...state,
                enrolledCourses: action.enrolledCourses
            };
        case COURSE_SELECT:
            return {
                ...state,
                id: course?.id,
                name: course?.name,
                categoryID: course?.categoryID,
                categoryName: course?.categoryName
            };
        case COURSE_REOMVE: {
            return {
                ...state,
                course
            };
        }
        default:
            return state || null;
    }
};

export default courseReducer;
