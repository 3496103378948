// material-ui
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import CustomHeader from './Header/CustomHeader';
import Footer from './Footer';
// import AppBar from 'ui-component/extended/AppBar';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => (
    <>
        <Grid container margin={7}>
            <Grid>
                <CustomHeader />
            </Grid>
        </Grid>
        <Grid
            marginLeft={{ xs: '20px', md: '13%', lg: '13%', xl: '18%' }}
            marginRight={{ xs: '20px', md: '13%', lg: '13%', xl: '18%' }}
            marginBottom={{ xs: 2, md: 10, lg: 10 }}
        >
            <Outlet />
        </Grid>
        <Grid>
            <Footer />
        </Grid>
    </>
);

export default MainLayout;
