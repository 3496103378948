const HeaderModules = [
    {
        name: 'Home',
        link: '/home'
    },
    {
        name: 'All Courses',
        link: 'all-courses'
    },
    {
        name: 'Report Card',
        link: 'report-card'
    },
    {
        name: 'Invoice',
        link: 'invoice'
    }
    // {
    //     name: 'Leaderboard',
    //     link: 'leaderboard'
    // },
    // {
    //     name: 'Reports',
    //     link: 'reports'
    // },
    // {
    //     name: 'Feedback',
    //     link: 'feedback'
    // },
    // {
    //     name: 'FAQ',
    //     link: 'faq'
    // }
];

export default HeaderModules;
