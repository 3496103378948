import { lazy } from 'react';

// project imports
// import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const LandingPage = Loadable(lazy(() => import('views/pages/Landing')));
const AllCoursesPage = Loadable(lazy(() => import('views/pages/AllCourses')));
const ProfileDetails = Loadable(lazy(() => import('views/pages/ProfileDetails')));
const CourseDetails = Loadable(lazy(() => import('views/pages/CourseDetails')));
const CourseContent = Loadable(lazy(() => import('views/pages/CourseContent')));
const QuizResponse = Loadable(lazy(() => import('views/pages/CourseContent/QuizContent/QuizResponse')));
const ViewScore = Loadable(lazy(() => import('views/pages/ViewScore')));
const ReportCard = Loadable(lazy(() => import('views/pages/Report')));
const ReportCardDetails = Loadable(lazy(() => import('views/pages/ReportDetails')));
const AttendenceReport = Loadable(lazy(() => import('views/pages/Attendence')));
const Calendar = Loadable(lazy(() => import('views/pages/calendar')));
const Forum = Loadable(lazy(() => import('views/pages/Forum')));
const Discussion = Loadable(lazy(() => import('views/pages/Forum/Discussion')));
const Invoice = Loadable(lazy(() => import('views/pages/Invoice')));
const ComingSoon = Loadable(lazy(() => import('views/pages/ComingSoon')));
const Error = Loadable(lazy(() => import('views/pages/Error')));
const Forbidden = Loadable(lazy(() => import('views/pages/Forbidden')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/home',
            element: <LandingPage />
        },
        {
            path: '/all-courses',
            element: <AllCoursesPage />
        },
        {
            path: '/all-courses/:id',
            element: <AllCoursesPage />
        },
        {
            path: '/profile',
            element: <ProfileDetails />
        },
        {
            path: '/details/:id',
            element: <CourseDetails />
        },
        {
            path: '/content/:id/',
            element: <CourseContent />
        },
        {
            path: '/content/:id/:accessCode',
            element: <CourseContent />
        },
        {
            path: '/quiz-response/:quizId',
            element: <QuizResponse />
        },
        {
            path: '/view-score/:id',
            element: <ViewScore />
        },
        {
            path: '/report-card',
            element: <ReportCard />
        },
        {
            path: '/report-card/:id',
            element: <ReportCardDetails />
        },
        {
            path: '/attendence-report/:courseId',
            element: <AttendenceReport />
        },
        {
            path: '/leaderboard',
            element: <ComingSoon />
        },
        {
            path: '/reports',
            element: <ComingSoon />
        },
        {
            path: '/feedback',
            element: <ComingSoon />
        },
        {
            path: '/faq',
            element: <ComingSoon />
        },
        {
            path: '/my-files',
            element: <ComingSoon />
        },
        {
            path: '/calendar',
            element: <Calendar />
        },
        {
            path: '/section/:moduleId/forum/:forumId',
            element: <Forum />
        },
        {
            path: '/discussion/:topicId',
            element: <Discussion />
        },
        {
            path: '/invoice',
            element: <Invoice />
        },
        {
            path: '/*',
            element: <Error />
        },
        {
            path: '/forbidden',
            element: <Forbidden />
        }
    ]
};

export default MainRoutes;
